<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12.mb-2.title-box
      b-button(variant="secondary" to="/line-broadcast") 回上一層
    .col-lg-12.mt-2
      b-card.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row
            .header-title Line 推播設定

        b-card-body
          .row
            .col-4.row
              .col-12
                .form-group.mb-3
                  label 推播標題 (僅後台可見)
                  br
                  input.form-control(
                    type="text",
                    v-model="currentTitle"
                    placeholder="請輸入推播標題"
                  )
              .col-12
                .form-group.mb-3
                  label 通知內文
                  br
                  textarea.form-control(
                    rows="3"
                    v-model="currentContent"
                    placeholder="這段文字將會顯示在 Line 推播的通知內文中, 此欄位必填"
                  )
            .col-4.row
              .col-12
                .form-group.mb-3
                  label 首則新聞標籤
                  br
                  b-button.btn-rounded.ml-1(
                    :variant="currentNewsTag === 0 ? 'primary' : 'outline-primary'",
                    @click="setCurrentNewsTag(0)"
                  ) 不帶標
                  b-button.btn-rounded.ml-1(
                    :variant="currentNewsTag === 1 ? 'primary' : 'outline-primary'",
                    @click="setCurrentNewsTag(1)"
                  ) 快訊標
                  b-button.btn-rounded.ml-1(
                    :variant="currentNewsTag === 2 ? 'primary' : 'outline-primary'",
                    @click="setCurrentNewsTag(2)"
                  ) 獨家標
                  b-button.btn-rounded.ml-1(
                    :variant="currentNewsTag === 3 ? 'primary' : 'outline-primary'",
                    @click="setCurrentNewsTag(3)"
                  ) 影音標

      b-card.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row
            .header-title 推播內容設置

        b-card-body
          .row
            .col-lg-2
            .col-lg-8.col-sm-12.col-md-12
              .content-item(v-for="(item, index) in broadcast.contents")
                newsItem(
                  :key="'line-broadcast-' + index"
                  :canUseAd="false"
                  :regionId="46" 
                  :index="index" 
                  :item="item" 
                  :showImage="(index <= 2) ? true : false"
                  @setItemData="setItemData"
                  @removeItem="removeItem"
                )
              .row
                .col
                  b-button.col-lg-9.add-btn.width-lg.mb-2(
                    v-if="broadcast.contents.length < 9"
                    variant="outline-primary"
                    @click="addItem()"
                  ) ＋新增一則 （最多 9 則） 

      b-card.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row
            .header-title 推送時間設定

        b-card-body
          .row
            .col-10.row
              .col-6
                .form-group.mb-2
                  label 推送時間
                  br
                  .title-box
                    b-button-group.tab-group.pl-0.pt-0
                      b-button.mr-2(:variant="ScheduleType !== 1 ? 'outline-primary' : 'primary'" @click="changeDateTab(1)") 立即
                      b-button.mr-2(:variant="ScheduleType !== 2 ? 'outline-primary' : 'primary'" @click="changeDateTab(2)") 排程

                .form-group.mb-3(v-if="ScheduleType === 2")
                  label 排程日期
                  br
                  .row
                    .col-6
                      date-picker(
                        v-model="currentScheduleDate",
                        format="YYYY-MM-DD"
                        value-type="format",
                        append-to-body,
                        lang="zh",
                        placeholder="請選擇推送日期"
                      )
                    .col-6
                      date-picker(
                        v-model="currentScheduleTime",
                        format="HH:mm",
                        value-type="format",
                        type="time",
                        placeholder="時 : 分"
                      )

      .row.text-center.mt-3
        .col
          b-button.width-lg.mr-1(variant="primary",v-if="currentBroadcastId === 'create'",@click="createBroadcast") 新增
          b-button.width-lg.mr-1(variant="primary",v-else-if="broadcast.status <= 1",@click="putBroadcast") 更新
          b-button.width-lg.mr-1(variant="danger",v-if="currentBroadcastId !== 'create' && broadcast.status <= 1",@click="cancelBroadcast") 取消
</template>
  
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import Select2 from 'v-select2-component';
import SelectImage from "@/components/select-image";
import draggable from 'vuedraggable'
import newsItem from "@/components/news-item/news-item"
/**
 * Starter component
 */
export default {
  data () {
    return {
      title: "推播編輯",
      items: [
        {
          text: "推播列表",
          href: "/broadcast",
        },
        {
          text: "推播編輯",
          active: true,
        }
      ],
      defaultItem: {
        useAd: false,
        useLink: false,
        typeInfo: {},
        link: "",
        title: "",
        image: "",
        adCode: "",
      },
      broadcast: {
        status: 0,
        contents: []
      },
      // 推送時間
      ScheduleType: 2,
      currentTitle: '',
      currentContent: '',
      currentScheduleDate: null,
      currentScheduleTime: null,
      currentBroadcastId: false,
      currentNewsTag: 0,
    };
  },
  computed: {

  },
  mounted () {

  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    Select2,
    SelectImage,
    draggable,
    newsItem
  },
  created () {
    if (this.$route.params.broadcastId) {
      this.currentBroadcastId = this.$route.params.broadcastId
    }

    // 預先建立 4 則稿件項目
    if (this.currentBroadcastId == 'create') {
      for (let i = 1; i <= 4; i++) {
        this.broadcast.contents.push(this.defaultItem)
      }
    }

    this.getBroadcastDetail()
    this.setBroadCastInit()
  },
  methods: {
    // 獲得推播項目內容
    getBroadcastDetail () {
      if (this.currentBroadcastId === 'create') {
        return
      }

      let vm = this
      this.$root.apis.getLineBroadcastItem(this.currentBroadcastId, function (_response) {
        let broadcast = _response.body.data
        vm.currentTitle = broadcast.title
        vm.currentContent = broadcast.content
        vm.currentNewsTag = broadcast.tag_type
        vm.ScheduleType = broadcast.schedule_type
        vm.currentScheduleDate = vm.$root.common.getNowTime('date', broadcast.schedule_time + ':00')
        vm.currentScheduleTime = vm.$root.common.getNowTime('time', broadcast.schedule_time + ':00')
        vm.broadcast = broadcast
      })
    },
    setCurrentNewsTag (_type) {
      this.currentNewsTag = _type
    },
    // 初始化推播項目建立
    setBroadCastInit () {
      if (this.currentBroadcastId !== 'create') {
        return
      }

      let targetDate = new Date()
      targetDate.setHours(targetDate.getHours() + 1)

      // 取得當下時間
      this.currentScheduleDate = this.$root.common.getNowTime('date', targetDate)
      this.currentScheduleTime = this.$root.common.getNowTime('time', targetDate)
    },
    // 搜尋新聞目標項目
    searchNewsList (_keyword) {
      let vm = this
      vm.NewsTargetOptions = []
      this.$root.apis.getFastNewsList({
        name: _keyword
      }, function (_response) {
        let items = _response.body.data.items
        items.forEach(function (_item) {
          vm.NewsTargetOptions.push(_item)
        })
      })
    },
    // 整理需要送出的資料
    processBroadcastData () {
      let vm = this

      if (this.broadcast.contents.length < 4) {
        this.$root.common.showErrorNotify("Line 推播的項目最少 4 則哦！")
        return false
      }

      let errContentIndexs = ""
      this.broadcast.contents.forEach(function (_braodcast, _index) {
        if (!_braodcast.title) {
          if (errContentIndexs) {
            errContentIndexs += ","
          }
          errContentIndexs += (_index + 1)
        }
      })

      if (errContentIndexs) {
        vm.$root.common.showErrorNotify("第 " + errContentIndexs + " 則項目的內容好像沒有填寫完畢！")
        return false
      }

      return {
        title: this.currentTitle,
        tag_type: this.currentNewsTag,
        content: this.currentContent,
        schedule_type: this.ScheduleType,
        schedule_time: this.currentScheduleDate + ' ' + this.currentScheduleTime + ':00',
        contents: this.broadcast.contents
      }
    },
    // 請求建立推播項目
    createBroadcast () {
      let data = this.processBroadcastData()
      console.log('=> data:', data)

      if (!data) {
        return
      }

      let vm = this
      this.$root.apis.createLineBroadcast(data,
        function (_response) {
          let broadcastId = _response.body.data
          vm.currentBroadcastId = broadcastId
          vm.$root.common.showSingleNotify("Line 推播項目已經建立成功！")
          vm.$router.push({ name: 'LineBroadCastEditor', params: { broadcastId: broadcastId } })
        },
        function (_error) {
          Array.isArray(_error.body.data?.body)
            ? vm.$root.common.showErrorNotify(_error.body.data.body.join('、'))
            : vm.$root.common.showErrorNotify(_error.body.data)
        }
      )
    },
    // 請求更新推播項目
    putBroadcast () {
      let data = this.processBroadcastData()
      console.log('=> data:', data)

      if (!data) {
        return
      }

      let vm = this
      this.$root.apis.putLineBroadcast(this.currentBroadcastId, data,
        function (_response) {
          vm.getBroadcastDetail()
          vm.$root.common.showSingleNotify("LIne 推播項目已經更新成功！")
        },
        function (_error) {
          Array.isArray(_error.body.data?.body)
            ? vm.$root.common.showErrorNotify(_error.body.data.body.join('、'))
            : vm.$root.common.showErrorNotify(_error.body.data)
        }
      )
    },
    // 請求取消推播項目
    cancelBroadcast () {
      let vm = this
      this.$root.common.confirmAction("取消推播", "您正在嘗試取消這個推播項目，確定要取消嗎？", "對，取消推播", "先不要", vm.processCancelBroadcastItem)
    },
    // 確認處理取消推播功能
    processCancelBroadcastItem () {
      let vm = this
      this.$root.apis.cancelLineBroadcastItem(this.currentBroadcastId,
        function (_response) {
          vm.getBroadcastDetail()
          vm.$root.common.showSingleNotify("推播項目已經取消成功！")
        },
        function (_error) {
          vm.$root.common.showErrorNotify(_error.body.data)
        }
      )
    },
    // 更換推送排程方式
    changeDateTab (_status) {
      this.ScheduleType = _status
    },
    // 更新新聞項目資料
    setItemData (_setItem, _index) {
      console.log("=> 更新新聞項目資料:", _setItem)
      this.broadcast.contents.splice(_index, 1, _setItem)
    },
    // 增加一個新聞項目
    addItem () {
      this.broadcast.contents.push(this.defaultItem)
    },
    // 移除項目
    removeItem (_index, _regionId) {
      this.broadcast.contents.splice(_index, 1)
    }
  }
};
</script>

<style lang="sass" scoped>
.title-box
  display: flex
  justify-content: space-between
  align-items: center
  .addbtn
    display: flex
    justify-content: center
    align-items: center
    .ri-file-add-fill
      font-size: 1.4rem
      line-height: 1.5rem
.card-header
  background: #fff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1

.tab-group
  // background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)

.form-label-row
  display: flex
  gap: 14px

.add-btn
  width: 100%
  border: dashed 1px
.del-btn
  flex: 1
  display: flex
  align-items: center
  position: relative
  top: 3px
  .btn
    padding: 0rem .3rem
    border-radius: 50px
.img-view
  width: 400px
  height: 300px
  background: #e2e8f0
  border-radius: 5px
  margin-top: 5px
  border: 1px solid #ccc
  display: flex
  align-items: center
  justify-content: center
  position: relative
  i
    font-size: 30px

.gray-box
  background: #f1f5f9
  width: 100%
  padding: 10px
  display: flex
  justify-content: center

::v-deep .select2-container--default
  display: block
  width: 100%
  height: calc(1.5em + 0.9rem + 2px)
  // padding: 0.45rem 0.9rem
  font-size: 0.9rem
  font-weight: 400
  line-height: 1.5
  color: #6c757d
  background-color: #fff
  background-clip: padding-box
  border: 1px solid #ced4da
  border-radius: 0.2rem
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
  outline: none

::v-deep .select2-selection--single
  border: none
  display: flex
  align-items: center
  height: 100%
.form-label-row
  display: flex
  gap: 14px
::v-deep .table-btn
  text-decoration: underline
  color: #0073b8
  background: none
  border: none
  padding: 0
::v-deep .width-auto
  min-width: 7rem
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center

.option-item
  display: flex
  gap: 10px
  width: 100%
  align-items: center
  ::v-deep .custom-control-label::after
    top: 45%
  ::v-deep .custom-control-label::before
    top: 45%
</style>
